import React from 'react'
import Layout from 'layouts/PlainLayout'
import SEO from 'components/SEO'
import cn from 'classnames'

import styles from './unsubscribe.module.scss'

import unsubscribeImg from 'images/unsubscribe.svg'

const UnsubscribePage = () => {
    return (
        <Layout>
            <SEO
                title="Shift Marketing"
                description="Partner with Shift to optimize your university or college constituent engagement and fundraising campaigns."
                canonical={'https://shiftmarketing.io/unsubscribe'}
                ogUrl={'https://shiftmarketing.io/unsubscribe'}
                siteName={'Shift Marketing | Unsubscribe '}
            />

            <div className={styles.unsubscribeContainer}>
                <div className={styles.inner}>
                    <h1>Are you sure you want to unsubscribe?</h1>
                    <img src={unsubscribeImg} alt="Unsubscribe" />
                    <p>
                        We are sad to see you go. Are you sure you wish to
                        unsubscribe from our emails?
                    </p>
                    <a
                        className={cn('sh-button-primary', styles.buttonNo)}
                        href="#"
                    >
                        No
                    </a>{' '}
                    <a
                        className={cn('sh-button-primary', styles.buttonYes)}
                        href="#"
                    >
                        Yes
                    </a>
                </div>
            </div>
        </Layout>
    )
}

export default UnsubscribePage

import React from 'react'
import PropTypes from 'prop-types'
// import { useStaticQuery, graphql } from 'gatsby'

import 'styles/global.scss'
import 'swiper/swiper.scss'

const Layout = props => {
    //const data = useStaticQuery(graphql`
    //   query PlainLayoutTitleQuery {
    //       site {
    //           siteMetadata {
    //       title
    // }
    //        }
    //    }
    //`)
    return (
        <div style={props.style}>
            <main>{props.children}</main>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
